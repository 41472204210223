import {getErrorMessage, useBaseObjectForm} from "../../helpers/helpers";
import {createStyles, PasswordInput, TextInput, Button, Checkbox} from "@mantine/core";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useState} from "react";
import {useSanctum} from "react-sanctum";

const Login = ({page}) => {
    const navigate = useNavigate()
    const {classes} = useStyles()
    const location = useLocation()

    const {data, errors, setErrors, onDataChange}: any = useBaseObjectForm({
        email: '',
        password: '',
        remember: false
    })
    const {authenticated, user, signIn} = useSanctum();

    const submitLogin = (e) => {
        e.preventDefault()

        signIn(data.email, data.password, data.remember)
            .then(() => {

                if (location && location.state && location.state.from) navigate(location.state.from)
                else navigate('/dashboard')
            })
            .catch(r => {
                if (r.response.status === 422) {
                    setErrors(r.response.data.errors)
                }
            })
    }

    const submitRemind = (e) => {
        e.preventDefault()
        console.log('remind')
    }

    const getHeader = () => {
        switch (page) {
            case 'login':
                return 'Avaro'
            case 'forgot':
                return 'Remind password'
            case 'register':
                return 'Register'
            default:
                return ''
        }
    }

    return <div className={classes.page}>

        {
            page &&
            <div className={classes.form_wrapper}>
                <div className={classes.header}>
                    <label className={'heading__text'}>{getHeader()}</label>
                </div>

                {
                    page === 'login' && <>

                        <form onSubmit={submitLogin}>
                            <TextInput label={'Email'}
                                       error={getErrorMessage(errors, 'email')}
                                       value={data.email}
                                       onChange={(e) => onDataChange({email: e.target.value})}/>
                            <PasswordInput label={'Password'}
                                           error={getErrorMessage(errors, 'password')}
                                           value={data.password}
                                           onChange={(e) => onDataChange({password: e.target.value})}/>

                            <Checkbox style={{marginTop: 10}} label={'Remember me'}
                                      checked={data.remember} onChange={() => onDataChange({remember: !data.remember})}/>


                            <div className={classes.button_placement}>
                                <Link to={'/forgot'} className={classes.muted_link}>Forgot password?</Link>
                                <Button className={classes.button} type={'submit'}>Login</Button>
                            </div>
                        </form>
                    </>
                }
                {
                    page === 'forgot' && <>
                        <form onSubmit={submitRemind}>
                            <TextInput label={'Email'}
                                       error={getErrorMessage(errors, 'email')}
                                       value={data.email}
                                       onChange={(e) => onDataChange({email: e.target.value})}/>
                            <div className={classes.button_placement}>
                                <Link to={'/login'} className={classes.muted_link}>Back to login</Link>
                                <Button className={classes.button} type={"submit"}>Remind</Button>
                            </div>
                        </form>
                    </>
                }
            </div>
        }
    </div>
}

export default Login

const useStyles = createStyles((theme) => ({
    button: {
        padding: '8px 32px',
        fontSize: '.875rem',
        fontWeight: 600,
    },
    header: {
        padding: '1rem 0 1.2rem',
        '.heading__text': {
            color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : '#1A0326',
            padding: '1rem 0 1.2rem',
            fontSize: '1.875rem',
            fontWeight: 500,
        }
    },
    page: {
        flex: 1,
        width: '100vw',
        boxSizing: 'border-box',
        minHeight: '100vh',
        // background: 'linear-gradient(20deg, rgba(116,61,123,1) 0%, rgba(119,72,155,1) 50%, rgba(124,81,178,1) 85%, rgba(128,93,209,1) 100%)',
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : '#f7f8fb',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    muted_link: {
        fontSize: '0.75rem',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6]
    },
    form_wrapper: {
        boxShadow: theme.colorScheme === 'dark' ? '4px 4px 10px 0 rgb(255 255 255 / 2%)' : '4px 4px 16px 0 rgb(77 83 224 / 20%)',
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        padding: '24px 32px',
        maxWidth: 450,
        width: '100%',
        borderRadius: 4,
        height: 'max-content',
        marginBottom: 20,
        transition: 'max-height 0.5s',
        'h2': {
            color: theme.colorScheme === 'dark' ? theme.colors.gray[4] : theme.colors.brand[6],
            textShadow: '1px 1px 1px rgb(0 0 0 / 10%)',
            marginTop: 5
        }
    },
    button_placement: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }
}));
