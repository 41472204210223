import {Group, ThemeIcon} from "@mantine/core";
import {Check, ExclamationMark, X} from "tabler-icons-react";

const DomainStatusBox = ({item}) => {
    return <Group>
        <ThemeIcon radius={25}
                   color={
                       item.status === 'healthy'
                           ? 'green'
                           : item.status === 'incomplete'
                               ? 'blue'
                               : 'red'
                   }
                   size={18}>
            {
                item.status === 'healthy'
                    ? <Check/>
                    : item.status === 'incomplete'
                        ? <ExclamationMark/>
                        : <X/>
            }

        </ThemeIcon>
        <div>{item.status_text ? item.status_text : item.status ? item.status : ''}</div>
    </Group>
}

export default DomainStatusBox
