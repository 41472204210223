import {pageBaseStyles} from "../../styles";
import {ActionIcon, Menu, Table, TextInput, Title, Button} from "@mantine/core";
import TableLoadingSkeleton from "../../components/TableLoadingSkeleton";
import {useEffect, useState} from "react";
import {DotsVertical, Plus, Refresh, Search} from "tabler-icons-react";
import {useNavigate} from "react-router-dom";
import DomainStatusBox from "../../components/DomainStatusBox";
import {API} from "../../helpers/api";
import {useSanctum} from "react-sanctum";

const OrganizationDomainList = () => {
    const {user} = useSanctum()
    const navigate = useNavigate()
    const baseClasses = pageBaseStyles().classes
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])


    const getData = () => {
        setLoading(true)
        API.get(`/organization/${user.organization_id}/domains`)
            .then(r => {
                setData(r.data.data)
                setLoading(false)
            })
            .catch(e => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getData()
    }, [user]);

    return <>
        <div className={baseClasses.card}>
            <Title order={3} mb={20} mt={0}>Domains</Title>

            <div>
                <div style={{marginBottom: 20}}>
                    <TextInput variant={'filled'}
                               icon={<Search size={16}/>}
                               placeholder={'Domain name...'}
                    />
                </div>

                <div style={{display: 'flex', gap: 10, marginBottom: 20}}>
                    <Button compact size={'xs'} variant={"subtle"} leftIcon={<Plus size={18}/>}>
                        Add domain
                    </Button>
                    <Button compact size={'xs'} variant={'subtle'}>Buy domain</Button>
                    <Button compact size={'xs'} variant={'subtle'} leftIcon={<Refresh size={18}/>} onClick={()=>getData()}>
                        Refresh list
                    </Button>
                </div>

                <Table highlightOnHover style={{tableLayout: 'fixed'}} fontSize={12}
                       className={'table-avr ' + baseClasses.table}>
                    <thead>
                    <tr>
                        <th>Domain name</th>
                        <th style={{width: 40}}></th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        loading
                            ? <TableLoadingSkeleton columnCount={4} rowCount={4}/>
                            : <>
                                {
                                    data && data.length > 0 && data.map((item, index) => <tr key={`row-${index}`}>
                                            <td>
                                                {
                                                    item.id
                                                        ? <a onClick={() => navigate(`/organization/domains/${item.id}`)}
                                                             className={baseClasses.link}>
                                                            {item.name}
                                                        </a>
                                                        : <div>{item.name}</div>
                                                }
                                            </td>
                                            <td style={{width: 'min-content'}}>
                                                <Menu radius={0}>
                                                    <Menu.Target>
                                                        <ActionIcon size={20}><DotsVertical size={20}/></ActionIcon>
                                                    </Menu.Target>
                                                    <Menu.Dropdown>
                                                        <Menu.Item
                                                            onClick={() => navigate(`/organization/domains/${item.id}`)}>View</Menu.Item>
                                                        <Menu.Item>Action 2</Menu.Item>
                                                        <Menu.Item>Action 3</Menu.Item>
                                                    </Menu.Dropdown>
                                                </Menu>
                                            </td>
                                            <td>
                                                <DomainStatusBox item={item}/>
                                            </td>
                                            <td></td>
                                        </tr>
                                    )
                                }
                            </>

                    }
                    </tbody>
                </Table>
            </div>
        </div>
    </>
}

export default OrganizationDomainList
