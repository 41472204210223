import {showNotification} from "@mantine/notifications";
import {Check} from "tabler-icons-react";

export const showDefaultNotification = ({
                                            autoClose = undefined,
                                            title = undefined,
                                            message = undefined,
                                            color = undefined,
                                            icon = undefined
                                        }) => {
    showNotification({
        autoClose: autoClose ? autoClose : 2500,
        title: title ? title : ``,
        message: message ? message : '',
        color: color ? color : 'green',
        icon: icon ? icon : <Check/>,
    })
}
