import {ActionIcon, createStyles, Group, Table, Title} from "@mantine/core";
import {useState} from "react";
import {Pencil, Trash} from "tabler-icons-react";

const OrganizationPeople = () => {
    const {classes} = useStyles()
    const [people, setPeople] = useState([
        {id: '1', name: 'Rytis Kazlauskas', email: 'a@a.com', position: 'Dev'},
        {id: '2', name: 'Rytis Kazlauskas2', email: 'b@a.com', position: 'Dev2'},
        {id: '3', name: 'Rytis Kazlauskas3', email: 'c@a.com', position: 'Dev3'},
    ])

    return <>
        <Title order={3} mb={20} mt={10}>Organization people</Title>

        <div className={classes.card}>

            <div style={{display: 'flex', gap: 10}}>
                <Table fontSize={'xs'} highlightOnHover style={{tableLayout: 'fixed'}} className={'table-avr'}>
                    <thead>
                    <tr>
                        <th style={{maxWidth: 250}}>User</th>
                        <th>Email</th>
                        <th>Position</th>
                        <th style={{width: 80}}></th>
                    </tr>
                    </thead>

                    <tbody>
                    {
                        people && people.length > 0 && people.map((item, index) => <tr key={`row-${index}`}>
                                <td>{item.name}</td>
                                <td>{item.email}</td>
                                <td>{item.position}</td>
                                <td>
                                    <div style={{display: 'flex', gap: 5}}>
                                        <ActionIcon size={18}> <Pencil/> </ActionIcon>
                                        <ActionIcon size={18} color={'red'}> <Trash/> </ActionIcon>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>


        </div>
    </>
}

export default OrganizationPeople

const useStyles = createStyles((theme) => ({
    card: {
        padding: 20,
        background: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        color: theme.colorScheme === 'dark' ? theme.colors.gray[4] : theme.colors.dark[7]
    },
    table: {
        'th': {
            background: theme.colorScheme === 'dark' && 'transparent !important',
        },
        'th, td': {
            borderColor: theme.colorScheme === 'dark' && '#686868 !important'
        }
    }
}));
