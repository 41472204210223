import {createStyles} from "@mantine/core";

const pageBaseStyles = createStyles((theme) => ({
    card: {
        padding: 20,
        background: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        color: theme.colorScheme === 'dark' ? theme.colors.gray[4] : theme.colors.dark[7]
    },
    table: {
        'th': {
            background: theme.colorScheme === 'dark' && 'transparent !important',
        },
        'th, td': {
            borderColor: theme.colorScheme === 'dark' && '#686868 !important'
        }
    },
    link: {
        color: '#7248e6',
        cursor: 'pointer'
    }
}));


export {
    pageBaseStyles
}
