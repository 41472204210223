import {createStyles, Grid, useMantineTheme, Button, Indicator, UnstyledButton} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {pageBaseStyles} from "../../styles";
import {Link} from "react-router-dom";

const Dashboard = () => {

    const {classes} = useStyles()
    const baseClasses = pageBaseStyles().classes
    const theme = useMantineTheme()


    return <>
        <Grid>
            <Grid.Col md={6}>
                <div className={classes.card + ' ' + baseClasses.card}>
                    <div className={'card__header'}>
                        <div className={'card__header_label'}>
                            <Indicator color={'red'} position={'middle-start'}>
                                <div/>
                            </Indicator>

                            <div>Invoice</div>
                        </div>
                        <div className={'card__header_status'}>Unpaid</div>
                    </div>
                    <div className={'card__content'}
                         style={{display: "flex", gap: 10, alignItems: 'end', justifyContent: 'space-between'}}>
                        <div style={{display: 'flex', gap: 10, alignItems: 'baseline', color: theme.colors.brand[6]}}>
                            <UnstyledButton
                                style={{
                                    color: theme.colors.brand[6],
                                    display: 'flex',
                                    alignItems: 'baseline',
                                    gap: 10
                                }}>
                                <FontAwesomeIcon icon={['fal', 'file-pdf']} size={'lg'}/>
                                <div style={{fontSize: 13}}>Atsisiųsti</div>
                            </UnstyledButton>
                        </div>

                        <div style={{display: 'flex', alignItems: 'flex-end', flexDirection: 'column'}}>
                            <div style={{fontWeight: 700, fontSize: 22}}>
                                123.33€
                            </div>
                            <div style={{fontWeight: 400, fontSize: 12}}>
                                2022-02-22
                            </div>
                            <div style={{marginTop: 5}}>
                                <Link to={'/organization/invoices'}>
                                    <Button variant={'light'} compact>All invoices</Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Grid.Col>
            <Grid.Col md={6}>
                <div className={classes.card + ' ' + baseClasses.card}>
                    SMS
                </div>
            </Grid.Col>
            <Grid.Col md={6}>
                <div className={classes.card + ' ' + baseClasses.card}>
                    Email
                </div>
            </Grid.Col>
        </Grid>
    </>
}
export default Dashboard

const useStyles = createStyles(() => ({
    card: {
        '.card__header': {
            '.card__header_label': {
                fontWeight: 600,
                letterSpacing: 0.5,
                display: 'flex',
                gap: 20,
                alignItems: 'center'
            },
            '.card__header_status': {
                fontWeight: 400,
                fontSize: 14,
                letterSpacing: 0.5
            }
        }
    }
}));
