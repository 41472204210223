import {pageBaseStyles} from "../../styles";
import {Table, Title, UnstyledButton, Badge} from "@mantine/core";
import TableLoadingSkeleton from "../../components/TableLoadingSkeleton";
import {useState} from "react";

const ApiKeyList = () => {
    const [loading, setLoading] = useState(false)
    const baseClasses = pageBaseStyles().classes
    const [data, setData] = useState([
        {
            id: '1',
            key: '********************8w6b4B',
            status: 'active',
            status_text: 'Active',
            updated_at: ''
        }
    ])

    const sendRevealRequest = (item) => {
        console.log(item)
    }

    return <>
        <div className={baseClasses.card}>
            <Title order={3} mb={20} mt={10}>Api keys</Title>

            <div>
                <div style={{display: 'flex', gap: 10, marginBottom: 20}}>

                    {/*<DatePicker label={'From'} placeholder={'Select...'} variant={'filled'}/>*/}
                    {/*<DatePicker label={'To'} placeholder={'Select...'} variant={'filled'}/>*/}
                </div>

                <Table highlightOnHover style={{tableLayout: 'fixed'}} fontSize={12}
                       className={'table-avr ' + pageBaseStyles().classes.table}>
                    <thead>
                    <tr>
                        <th>Key</th>
                        <th>Status</th>
                        <th>Updated at</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        loading
                            ? <TableLoadingSkeleton columnCount={5}/>
                            : <>
                                {
                                    data && data.length > 0 && data.map((item, index) => <tr key={`row-${index}`}>
                                            <td>
                                                <UnstyledButton style={{fontSize: 12, letterSpacing: 1}}
                                                                onClick={() => sendRevealRequest(item)}>
                                                    {item.key ? item.key : ''}
                                                </UnstyledButton>
                                            </td>
                                            <td>{item.status ?
                                                <Badge style={{letterSpacing: 0.5, fontWeight: 500}} variant={'filled'}
                                                       color={item.status === 'active' ? 'green.8' : 'gray.8'}>
                                                    {item.status_text}
                                                </Badge> : ''}
                                            </td>
                                            <td>{item.updated_at ? item.updated_at : ''}</td>
                                        </tr>
                                    )
                                }
                            </>

                    }

                    </tbody>
                </Table>
            </div>
        </div>
    </>
}

export default ApiKeyList
