import {useSanctum} from "react-sanctum";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {LoadingOverlay} from "@mantine/core";
import {getProfile} from "../backend/userAxios";

const Authenticated = () => {
    const {authenticated, setUser} = useSanctum()
    const location = useLocation()
    const navigate = useNavigate()

    if (authenticated === null) {
        getProfile().then(r => {
            setUser(r.data, true)

            if (location.pathname.match('/login|forgot|register/')) {
                navigate('/dashboard')
            }
        }).catch(e => {
            if (e.response.status === 401) {
                navigate('/login')
            }

            setUser(undefined, false)
        })
    }

    return <>
        {
            authenticated === null
                ? <LoadingOverlay visible overlayOpacity={1} loader={<></>}/>
                : <Outlet/>
        }
    </>
}

export {
    Authenticated
}
