import '../sass/app.scss'
import './AppIcons'
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import {Sanctum} from "react-sanctum";
// import * as Sentry from "@sentry/react";

import AppRouter from "./AppRouter";
import {API} from './helpers/api'

const sanctumConfig = {
    apiUrl: "",
    csrfCookieRoute: "sanctum/csrf-cookie",
    signInRoute: "auth/login",
    signOutRoute: "auth/logout",
    userObjectRoute: "profile",
    axiosInstance: API
};

/*Sentry.init({
    dsn: "https://b38c3baf8f274a50a6d9f2962c1a0905@o342985.ingest.sentry.io/5962329",
    // integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
});*/

const app = ReactDOM.createRoot(document.getElementById('root')!);

app.render(
    <Sanctum config={sanctumConfig} checkOnInit={false}>
        <Router>
            <AppRouter/>
        </Router>
    </Sanctum>
)
