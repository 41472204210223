import {Title, createStyles, Grid, Divider} from "@mantine/core";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import NewTicketModal from "../../components/modals/NewTicketModal";

const Tickets = () => {
    const {classes} = styles()
    const navigate = useNavigate()
    const [openNewReport, setOpenNewReport] = useState(false)
    const [data, setData] = useState([
        {name: 'Incidents', onClick: () => navigate('/tickets/incidents')},
        {name: 'Requests', onClick: () => navigate('/tickets/requests')},
        {name: 'Changes', onClick: () => navigate('/tickets/changes')},
    ])

    return <>
        <div>
            <Title order={3} mb={20} mt={0}>Tickets</Title>

            {
                openNewReport && <NewTicketModal opened={openNewReport} setOpen={setOpenNewReport}/>
            }

            <Grid style={{maxWidth: 1140}}>

                <Grid.Col sm={6} lg={3} onClick={() => setOpenNewReport(true)}>
                    <div className={classes.card} style={{color: '#7248e6', fontWeight: 500}}>
                        Create ticket
                    </div>
                </Grid.Col>
            </Grid>

            <Divider mt={20} mb={20}/>

            <Grid style={{maxWidth: 1140}}>

                {
                    data && data.length > 0 && data.map((item, index) => <Grid.Col key={`link-${index}`} sm={6} lg={3}>
                        <div className={classes.card} onClick={() => {
                            if (item.onClick) item.onClick()
                        }}>
                            {item.name}
                        </div>
                    </Grid.Col>)
                }

            </Grid>

        </div>
    </>
}

export default Tickets

const styles = createStyles((theme) => ({
    card: {
        padding: 20,
        background: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        color: theme.colorScheme === 'dark' ? theme.colors.gray[4] : theme.colors.dark[7],
        boxShadow: theme.colorScheme === 'dark' ? 'none' : '2px 2px 3px 1px #ddd',
        cursor: 'pointer',
        ':hover': {
            transform: 'scale(1.02)',
            transition: '0.1s'
        }
    },
}));
