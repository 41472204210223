import {useState} from "react";

export const useBaseObjectForm = (preDefinedValues = undefined) => {

    const [data, setData] = useState(preDefinedValues ? preDefinedValues : {})
    const [errors, setErrors] = useState({})

    const onDataChange = (values) => {
        console.log(values)
        let _errors = {...errors}
        Object.keys(values).map(key => {
            delete _errors[key]
        })
        setErrors(_errors)

        setData({...data, ...values})
    }

    return {data, setData, errors, setErrors, onDataChange}
}


const getErrorMessage = (errors, field) => {
    if (errors && errors[field] && errors[field][0])
        return errors[field][0];
    else
        return undefined
}

const cleanupAxiosData = (data, excludeKeys = []) => {
    let _data = {...data}

    Object.keys(_data).forEach(k => {
        if ((_data[k] === '' || _data[k] === null || _data[k] === undefined || _data[k].length === 0) && (excludeKeys && !excludeKeys.includes(k))) {
            delete _data[k]
        } else {
            if (typeof _data[k] === 'string') {
                if (_data[k] === 'null')
                    _data[k] = null
                else
                    _data[k] = _data[k].trim();
            }
        }
    })

    return _data;
}


const cleanupAxiosAdminMemberData = (data) => {
    let _data = {...data}

    Object.keys(_data).forEach(k => {
        if ((_data[k] === '' || _data[k] === null || _data[k] === undefined || _data[k].length === 0) && _data[k] !== 'export_status') {
            delete _data[k]
        } else {
            if (typeof _data[k] === 'string') {
                _data[k] = _data[k].trim();
            }
        }
    })

    return _data;
}

const convertSecondsToMinutesShow = (value) => {
    if (value < 600) return new Date(value * 1000).toString().substr(20, 4)
    return new Date(value * 1000).toString().substr(19, 5)
}

const convertSecondsToMinutes = (value) => {
    let sec: any = parseInt(value, 10); // convert value to number if it's string
    let hours: any = Math.floor(sec / 3600); // get hours
    let minutes: any = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    let seconds: any = sec - (hours * 3600) - (minutes * 60); //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }

    return minutes + ':' + seconds; // Return is HH : MM : SS
}

const convertSecondsToHoursMinutes = (value) => {
    let sec: any = parseInt(value, 10); // convert value to number if it's string
    let hours: any = Math.floor(sec / 3600); // get hours
    let minutes: any = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    let seconds: any = sec - (hours * 3600) - (minutes * 60); //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }

    return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
}

const removeDuplicatesFromArray = (array = [], field = 'id') => {
    const flag = {}
    const unique = []

    array.forEach(elem => {
        if (!flag[elem[field]]) {
            flag[elem[field]] = true
            unique.push(elem)
        }
    })

    return unique
}

const executeCommands = (commands) => {
    commands.forEach(command => command)
}

export {
    executeCommands,
    removeDuplicatesFromArray,
    convertSecondsToMinutesShow,
    convertSecondsToMinutes,
    convertSecondsToHoursMinutes,
    getErrorMessage,
    cleanupAxiosAdminMemberData,
    cleanupAxiosData
}
