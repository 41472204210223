import {
    UnstyledButton,
    UnstyledButtonProps,
    Group,
    Avatar,
    Text,
    createStyles,
} from '@mantine/core';
import {IconChevronRight} from '@tabler/icons';

const useStyles = createStyles((theme) => ({
    userWrapper: {
        width: '100%',
        padding: '10px 15px',
        color: theme.colors.brand[0],
        display: 'flex',
        gap: 10,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    user: {
        display: 'flex',
        gap: 10,
        flexWrap: 'wrap',
        alignItems: 'center'
    },
    userName: {
        fontSize: 14,
        marginBottom: 2
    },
    userEmail: {
        fontSize: 10.2
    }
}));

// TODO Export to interfaces some of the stuff...
interface UserButtonProps extends UnstyledButtonProps {
    user: {
        avatar: string|null;
        first_name: string;
        last_name: string;
        display_name: string;
        email: string
    }
    onClick: any;
    icon?: React.ReactNode;
}

export function UserButton({user, icon, onClick, ...others}: UserButtonProps) {
    const {classes} = useStyles();

    return (
        <UnstyledButton className={classes.userWrapper} onClick={onClick?onClick:undefined}{...others}>
            <div className={classes.user}>
                <Avatar src={user.avatar} size={35} radius="xl">{user?.first_name?.substring(0,1) + user?.last_name?.substring(0,1)}</Avatar>

                <div style={{flex: 1}}>
                    <div className={classes.userName}>
                        {user?.display_name}
                    </div>

                    <div className={classes.userEmail}>
                        {user?.email}
                    </div>
                </div>
            </div>
            {icon || <IconChevronRight size={14} stroke={1.5}/>}
        </UnstyledButton>
    )
}
