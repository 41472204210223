import {useEffect, useState} from "react";
import {openModal} from "@mantine/modals";
import ChangePassword from "../components/forms/ChangePassword";
import {extend} from "dayjs";

export const useBaseObjectForm = (preDefinedValues = undefined) => {

    const [data, setData] = useState(preDefinedValues ? preDefinedValues : {})
    const [errors, setErrors] = useState({})

    const onDataChange = (values) => {
        let _errors = {...errors}
        Object.keys(values).map(key => {
            delete _errors[key]
        })
        setErrors(_errors)

        setData({...data, ...values})
    }

    return {data, setData, errors, setErrors, onDataChange}
}

export const callPasswordChangeModal=()=>{
    openModal({
        modalId: 'password-change',
        title: 'Change password',
        centered: true,
        children: (
            <>
                <ChangePassword/>
            </>
        ),
    })
}
