import {Modal, Select, TextInput, Button, Stack, Input, MultiSelect, Divider} from "@mantine/core";
import {Dropzone} from "@mantine/dropzone";
import {RichTextEditor} from "@mantine/rte";
import {useBaseObjectForm} from "../../hooks/hooks";

const NewTicketModal = ({
                            opened = false,
                            setOpen,
                            onClose = () => {
                            },
                            type = ''
                        }) => {

    const onSelectClose = () => {
        setOpen(false)
    }

    const {data, onDataChange, errors} = useBaseObjectForm({
        type: '',
        summary: '',
        files: '',
        description: '',
        tickets: '',
    })

    return <>
        <Modal opened={opened}
               onClose={() => onSelectClose()}
               title={'Create ticket'}
               styles={{modal: {maxWidth: 600, width: '100%'}}}>
            <Stack>
                <Select data={[
                    {label: 'Incident', value: 'incident'},
                    {label: 'Request', value: 'request'},
                    {label: 'Changes', value: 'changes'}]}
                        value={data.type}
                        onChange={(op) => onDataChange({type: op})}
                        variant={'filled'}
                        label={'Issue type'}
                        required
                        placeholder={'Select'}/>

                <TextInput required
                           label={'Summary'}
                           placeholder={'......'}/>

                {
                    data.type && <Divider mt={10} mb={5}/>
                }

                {
                    data.type === 'incident' &&
                    <>
                        <Input.Wrapper label={'Attachments'}>
                            <Dropzone onDrop={() => console.log('a')} style={{minHeight: 100}}>
                                <div style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    Drop files to attack or browse
                                </div>
                            </Dropzone>
                        </Input.Wrapper>

                        <Input.Wrapper label={'Description'}>
                            <RichTextEditor styles={{
                                root: {minHeight: 200}
                            }}/>
                        </Input.Wrapper>

                        <MultiSelect label={'Associated tickets'} data={['IT-1123', 'FB-1322']}/>
                    </>
                }
            </Stack>
            <div style={{marginTop: 25, display: 'flex', gap: 10, justifyContent: 'flex-end'}}>
                <Button compact size={'xs'} variant={'subtle'} onClick={() => onSelectClose()}>Cancel</Button>
                <Button compact size={'xs'}>Create</Button>
            </div>
        </Modal>
    </>
}

export default NewTicketModal
