export const MantineThemeObject: any = {
    cursorType: 'pointer',
    fontFamily: `'Poppins', sans-serif`,
    loader: 'oval',
    globalStyles: () => ({
        body: {
            fontSize: '14px',

        },
    }),
    colors: {
        brand: [
            '#f3f0ff',
            '#e5dbff',
            '#D0BFFF',
            '#b197fc',
            '#9775fa',
            '#845ef7',
            '#7950f2',
            '#5f3dc4',
            '#5f3dc4',
            '#7950f2',
        ],
        dark: [
            '#C1C2C5',
            '#A6A7AB',
            '#909296',
            '#5C5F66',
            '#373A40',
            '#2C2E33',
            '#25262B',
            '#1A1B1E',
            '#141517',
            '#1A0326'
        ],
        gray: [
            '#F8F9FA',
            '#F1F3F5',
            '#E9ECEF',
            '#DEE2E6',
            '#CED4DA',
            '#ADB5BD',
            '#868E96',
            '#495057',
            '#343A40',
            '#212529'
        ]
    },
    primaryColor: 'brand',
    datesLocale: 'lt',
    defaultRadius: 3,
    activeStyles: {transform: 'scale(0.98)'},
    components: {
        Button: {
            styles: () => ({
                label: {
                    fontWeight: 500,
                },
            }),
        },
        TextInput: {
            styles: () => ({
                label: {
                    fontSize: 12,
                },
                input: {
                    fontSize: 12,
                    '::placeholder': {}
                },
            }),
        },
        Select: {
            styles: () => ({
                label: {
                    fontSize: 12,
                },
                input: {
                    fontSize: 12,
                    '::placeholder': {}
                },
                item: {
                    fontSize: 12,
                }
            }),
        },
        MultiSelect: {
            styles: () => ({
                label: {
                    fontSize: 12,
                },
                input: {
                    fontSize: 12,
                    '::placeholder': {}
                },
                item: {
                    fontSize: 12,
                }
            }),
        },
        Textarea: {
            styles: () => ({
                label: {
                    fontSize: 12,
                },
                input: {
                    fontSize: 12,
                    '::placeholder': {}
                },
            }),
        },
        PasswordInput: {
            styles: () => ({
                label: {
                    fontSize: 12,
                },
                input: {
                    fontSize: 12,
                    '::placeholder': {}
                },
            }),
        },
        InputWrapper: {
            styles: () => ({
                label: {
                    fontSize: 12,
                },
                input: {
                    fontSize: 12,
                    '::placeholder': {}
                },
            }),
        },
        DatePicker: {
            defaultProps: {
                firstDayOfWeek: 'monday',
                inputFormat: 'YYYY-MM-DD',
                labelFormat: 'YYYY-MM-DD'
            },
            styles: () => ({
                label: {
                    fontSize: 12,
                },
                input: {
                    fontSize: 12,
                    '::placeholder': {}
                },
                item: {
                    fontSize: 12,
                }
            }),
        },
        DateRangePicker: {
            defaultProps: {
                firstDayOfWeek: 'monday',
                inputFormat: 'YYYY-MM-DD',
                labelFormat: 'YYYY-MM-DD'
            },
            styles: () => ({
                label: {
                    fontSize: 12,
                },
                input: {
                    fontSize: 12,
                    '::placeholder': {}
                },
                item: {
                    fontSize: 12,
                }
            }),
        },
        Table: {
            styles: () => ({
                label: {
                    fontSize: 12,
                },
                input: {
                    fontSize: 12,
                    '::placeholder': {}
                },
                item: {
                    fontSize: 12,
                }
            }),
        },
        Menu: {
            styles: () => ({
                label: {
                    fontSize: 12,
                },
                input: {
                    fontSize: 12,
                    '::placeholder': {}
                },
                item: {
                    fontSize: 12,
                }
            }),
        },
    },
}
