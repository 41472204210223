import {Briefcase, ChevronLeft, LayoutDashboard, ListDetails} from "tabler-icons-react";
import {IconLock} from "@tabler/icons";
import {callPasswordChangeModal} from "./hooks";

const NavbarLinkWrapper = ({children}) => {
    const baseLinks = [
        {label: 'Dashboard', icon: LayoutDashboard, link: '/dashboard'},
        {
            label: 'Products',
            icon: IconLock,
            links: [
                {label: 'SMS sending', link: '/sms_sending'},
                {label: 'Email sending', link: '/email_sending'},
                {label: 'Infrastructure', onClick: () => callPasswordChangeModal()},
            ],
        },
        {
            label: 'Tickets',
            link: '/tickets',
            icon: ListDetails,
            links: [
                {label: 'Incidents', link: '/tickets/incidents'},
                {label: 'Requests', link: '/tickets/requests'},
                {label: 'Changes', link: '/tickets/changes'},
            ],
        },
        {
            label: 'Organization',
            icon: Briefcase,
            links: [
                {label: 'Overview', link: '/organization'},
                {label: 'Domains', link: '/organization/domains'},
                {label: 'Invoices', link: '/organization/invoices'},
                {label: 'People', link: '/organization/people'},
            ],
        },
        {
            label: 'Security',
            icon: IconLock,
            links: [
                {
                    label: 'API keys',
                    link: '/api_keys'
                },
            ],
        },
    ];
    const smsLinks = [
        {label: 'Back', icon: ChevronLeft, link: '/dashboard'},
        {isDivider: true},
        {label: 'Dashboard', icon: LayoutDashboard, link: '/sms_sending'},
        {
            label: 'Lists',
            links: [
                {label: 'Active sendings', link: '/sms_sending/active'},
                {label: 'Blacklist', link: '/sms_sending/blacklist'},
                {label: 'Sender ID', link: '/sms_sending/sender'},
            ],
        },
        {
            label: 'Statistics',
            links: [
                {label: 'Summary', link: '/sms_sending/summary'},
                {label: 'Detailed', link: '/sms_sending/detailed'},
                {label: 'Reports', link: '/sms_sending/reports'}
            ],
        },
        {
            label: 'Misc',
            links: [
                {label: 'Balance', link: '/sms_sending/balance'},
                {label: 'Settings', link: '/sms_sending/settings'},
                {label: 'Notifications', link: '/sms_sending/notifications'}

            ],
        },
        {label: 'API', link: '/sms_sending/api'},
    ];
    const emailLinks = [
        {label: 'Back', icon: ChevronLeft, link: '/dashboard'},
        {isDivider: true},
        {label: 'Dashboard', icon: LayoutDashboard, link: '/email_sending'},
        {
            label: 'Stats',
            links: [
                {label: 'Overview', link: '/email_sending'},
                {label: 'Deliverability Insights', link: '/email_sending'},
                {label: 'Global stats', link: '/email_sending'},
                {label: 'Account stats', link: '/email_sending'},
            ],
        },
        {
            label: 'Settings',
            links: [
                {label: 'Account details', link: '/email_sending'},
                {label: 'Domain settings', link: '/email_sending'},
                {label: 'Alert settings', link: '/email_sending'},
                {label: 'Mail Settings', link: '/email_sending'},
                {label: 'Sender Authentication', link: '/email_sending'}
            ],
        },
        {label: 'API', link: '/dashboard'},
    ];

    const getNavLinks = () => {
        let pathname: any = window.location.pathname

        if (pathname.includes('sms_sending')) return smsLinks
        if (pathname.includes('email_sending')) return emailLinks

        return baseLinks
    }

    return <>
        {children(getNavLinks())}
    </>
}

export default NavbarLinkWrapper
