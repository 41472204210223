import {Table, TextInput, Title, Select, Grid} from "@mantine/core";
import {useState} from "react";
import {Search} from "tabler-icons-react";
import {useNavigate} from "react-router-dom";
import {pageBaseStyles} from "../../styles";
import TableLoadingSkeleton from "../../components/TableLoadingSkeleton";

const RequestsList = () => {
    const baseClasses = pageBaseStyles().classes
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([{}])

    return <>
        <div className={baseClasses.card}>
            <Title order={3} mb={20} mt={0}>Requests</Title>

            <div>
                <div style={{marginBottom: 20, display: 'flex', gap: 10, flexDirection: 'column'}}>
                    <Grid>
                        <Grid.Col lg={8}>
                            <TextInput variant={'filled'}
                                       label={'Search'}
                                       icon={<Search size={16}/>}
                                       placeholder={'Enter Id, Summary, ...'}
                            />
                        </Grid.Col>
                        <Grid.Col lg={4} sm={5}>
                            <Select label={'Status'}
                                    placeholder={'Status'}
                                    data={['New', 'In progress', 'Solved']}
                                    variant={'filled'}/>
                        </Grid.Col>
                    </Grid>

                </div>

                <Table highlightOnHover style={{tableLayout: 'fixed'}} fontSize={12}
                       className={'table-avr ' + pageBaseStyles().classes.table}>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Status</th>
                        <th>Summary</th>
                        <th>-</th>
                        <th>-</th>
                        <th>-</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        loading
                            ? <TableLoadingSkeleton columnCount={5}/>
                            : <>
                                {
                                    data && data.length > 0 && data.map((item, index) => <tr key={`row-${index}`}>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>-</td>
                                        </tr>
                                    )
                                }
                            </>

                    }
                    </tbody>
                </Table>
            </div>
        </div>
    </>
}

export default RequestsList
