import {ActionIcon, createStyles, Table, Title, useMantineTheme} from "@mantine/core";
import {DatePicker} from "@mantine/dates";
import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import TableLoadingSkeleton from "../../components/TableLoadingSkeleton";

const BillingList = () => {
    const {classes} = useStyles()
    const theme = useMantineTheme()

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([
        {
            created: '2022-11-01',
            payed: '2022-11-01',
            amount: '40.64',
        },
        {
            created: '2021-21-03',
            payed: '2010-10-10',
            amount: '156.84',
        },
        {
            created: '2222-14-51',
            payed: '2022-11-11',
            amount: '15.98',
        }
    ])

    return <>
        <Title order={3} mb={20} mt={10}>Billing</Title>

        <div className={classes.card}>

            <div style={{display: 'flex', gap: 10, marginBottom: 20}}>

                <DatePicker label={'From'} placeholder={'Select...'} variant={'filled'}/>
                <DatePicker label={'To'} placeholder={'Select...'} variant={'filled'}/>
            </div>

            <Table highlightOnHover style={{tableLayout: 'fixed'}} fontSize={12}
                   className={'table-avr ' + classes.table}>
                <thead>
                <tr>
                    <th>Formed</th>
                    <th>Payed</th>
                    <th>Amount</th>
                    <th>-</th>
                    <th>Atsisiųsti</th>
                </tr>
                </thead>
                <tbody>

                {
                    loading
                        ? <TableLoadingSkeleton columnCount={5}/>
                        : <>
                            {
                                data && data.length > 0 && data.map((item, index) => <tr key={`billingItem-${index}`}>
                                    <td>{item.created}</td>
                                    <td>{item.payed}</td>
                                    <td>{`${item.amount ? item.amount : ''} €`}</td>
                                    <td>-</td>
                                    <td>
                                        <ActionIcon size={22} color={'blue.8'}>
                                            <FontAwesomeIcon icon={"fa-light fa-file-pdf" as IconProp} style={{fontSize: 21}}/>
                                        </ActionIcon>
                                    </td>
                                </tr>)
                            }
                        </>

                }

                </tbody>
            </Table>
        </div>
    </>
}

export default BillingList


const useStyles = createStyles((theme) => ({
    card: {
        padding: 20,
        background: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        color: theme.colorScheme === 'dark' ? theme.colors.gray[4] : theme.colors.dark[7]
    },
    table: {
        'th': {
            background: theme.colorScheme === 'dark' && 'transparent !important',
        },
        'th, td': {
            borderColor: theme.colorScheme === 'dark' && '#686868 !important'
        }
    }
}));
