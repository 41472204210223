import {createStyles, Divider, LoadingOverlay, Title,} from "@mantine/core";
import {OrgOverview, OrgSettings} from "./subPages/OrganizationComponents";
import {useEffect, useState} from "react";
import {getOrganization} from "../../backend/userAxios";

import {OrganizationProps} from "../../interfaces/customer";
import {useBaseObjectForm} from "../../hooks/hooks";
import {useSanctum} from "react-sanctum";

const OrganizationOverview = () => {
    const {classes} = useStyles()

    const [loading, setLoading] = useState<boolean>(true);
    const {user} = useSanctum()
    const {data, setData, errors, onDataChange, setErrors} = useBaseObjectForm({
        name: '',
        admins: [],
        email: '',
        phone: '',
        latest_invoice: null,
        billing_email: '',
        country: '',
        state: '',
        city: '',
        street: '',
        apartment: '',
        post_code: '',
    })

    useEffect(() => {
        getOrganization(user.organization_id)
            .then(r => {
                setData({...data, ...r.data})
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }, [])

    return <>
        <LoadingOverlay visible={loading} />
        <Title order={2} mb={20} mt={10}>Organization</Title>
        <div>
            <div className={classes.card}>

                <div className={classes.header}>
                    <div className={classes.header_user}>
                        <Title order={3} weight={500}
                               style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                            {data.name}
                        </Title>
                    </div>
                </div>

                <Divider mt={15} mb={10}/>
                <OrgOverview data={data} classes={classes}/>

                <Divider mt={15} mb={10}/>
                <OrgSettings data={data} onDataChange={onDataChange} errors={errors} setErrors={setErrors}/>
            </div>
        </div>
    </>
}
export default OrganizationOverview

const useStyles = createStyles((theme) => ({
    card: {
        padding: 20,
        background: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        color: theme.colorScheme === 'dark' ? theme.colors.gray[4] : theme.colors.dark[7]
    },
    tabs: {
        marginTop: 25
    },
    heading: {
        fontSize: '1rem',
        fontWeight: 600,
        marginBottom: 5
    },
    content: {
        fontSize: '0.9rem',
        fontWeight: 400,
        marginBottom: 5
    },
    content_group: {
        fontSize: '0.9rem',
        fontWeight: 400,
        display: 'flex',
        flexDirection: 'column',
        gap: 3
    },
    link_button: {
        fontSize: 12,
        color: '#7248e6',
        fontWeight: 500,
        cursor: 'pointer',
        textDecoration: 'none'
    },
    link_button_wi: {
        fontSize: 12,
        color: '#7248e6',
        fontWeight: 500,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: 2
    },
    header: {
        display: 'flex',
        gap: 20,
        alignItems: 'center',
        overflow: "hidden",
        textOverflow: 'ellipsis',
    },
    header_user: {
        overflow: "hidden",
        textOverflow: 'ellipsis',
    },

}));
