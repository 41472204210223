import axios from "axios";
import {captureException} from "@sentry/react";

const API = axios.create({
    baseURL: 'http://localhost:8000/api',
    withCredentials: true,
    headers: {
        common: {
            'Accept': 'application/json'
        }
    },
});
API.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status >= 500) {
        captureException(error)
    }
    return Promise.reject(error)
})

export {API}
