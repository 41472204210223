import {useState} from 'react';
import {Group, Box, Collapse, UnstyledButton, createStyles, Divider} from '@mantine/core';
import {IconChevronUp, IconChevronDown} from '@tabler/icons';
import {Link, useNavigate} from "react-router-dom";

const useStyles = createStyles((theme) => ({
    control: {
        fontWeight: 500,
        display: 'block',
        width: '100%',
        padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
        color: theme.colors.brand[0],
        // color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
        fontSize: 13,

        '&:hover': {
            // backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
            color: theme.colors.brand[0],
            // color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        },
    },

    link: {
        fontWeight: 500,
        display: 'block',
        textDecoration: 'none',
        padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
        paddingLeft: 31,
        marginLeft: 24,
        fontSize: 11,
        letterSpacing: 0.5,
        color: theme.colors.brand[0],
        // color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
        borderLeft: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
        }`,

        '&:hover': {
            // backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
            color: theme.colors.brand[0],
            // color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        },
    },

    chevron: {
        transition: 'transform 200ms ease',
    },
}));

export function LinksGroup({hasIcon, icon: Icon, label, link, initiallyOpened, links, closeMenu, isDivider}: any) {
    const navigate = useNavigate()
    const {classes, theme} = useStyles();
    const hasLinks = Array.isArray(links);
    const [opened, setOpened] = useState(initiallyOpened || false);
    const ChevronIcon = theme.dir === 'ltr' ? IconChevronDown : IconChevronUp;

    const getChevron = () => {
        return <>
            {hasLinks && (
                <ChevronIcon
                    className={classes.chevron}
                    size={18}
                    stroke={1.5}
                    style={{
                        transform: opened ? `rotate(${theme.dir === 'rtl' ? 180 : -180}deg)` : 'none',
                    }}
                />
            )}
        </>
    }

    return (
        <>
            {isDivider ? <Divider m={15}/>
                : <>
                    <UnstyledButton onClick={() => {
                        if (link) {
                            navigate(link)
                            closeMenu()
                        }
                        //else setOpened((o) => !o)
                        setOpened((o) => !o)
                    }}
                                    className={classes.control}>

                        <Group position="apart" spacing={0}>
                            {
                                hasIcon
                                    ? <>
                                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                                            {
                                                hasIcon
                                                    ? <Icon size={18}/>
                                                    : <div style={{width: 18, height: 18}}/>
                                            }
                                            <Box ml="md">{label}</Box>
                                        </Box>
                                        {getChevron()}
                                    </>
                                    : <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        {
                                            hasLinks
                                                ? getChevron()
                                                : <div style={{width: 18, height: 18}}/>
                                        }

                                        <Box ml="md">{label}</Box>
                                    </Box>
                            }


                        </Group>
                    </UnstyledButton>
                    {hasLinks ? <Collapse in={opened}>{
                        (hasLinks ? links : []).map((link, index) => (
                            <Link to={link.link}
                                  className={classes.link}
                                  key={`navlink-${index}`}
                                  onClick={() => {
                                      if (link.onClick) {
                                          link.onClick()
                                      }
                                  }}>
                                {link.label}
                            </Link>
                        ))
                    }</Collapse> : null}
                </>
            }
        </>
    );
}
