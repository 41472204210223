import {Navbar, Group, ScrollArea, createStyles, MediaQuery, Burger, useMantineTheme} from '@mantine/core';
import {UserButton} from "../components/layout/UserButton";
import {LinksGroup} from '../components/layout/LinksGroup';
import {Link, useNavigate} from "react-router-dom";
import {LayoutDashboard} from "tabler-icons-react";
// @ts-ignore
import avaroLogo from './../../images/avaro_logo.png'
import {useSanctum} from "react-sanctum";

const baseLinks = [
    {label: 'Dashboard', icon: LayoutDashboard, link: '/dashboard'},
];

const useStyles = createStyles((theme) => ({
    navbar: {
        zIndex: 500,
        backgroundColor: '#27043A',
        paddingBottom: 0,
        // backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        color: '#F3F0FF',
    },

    header: {
        padding: theme.spacing.md,
        marginLeft: -theme.spacing.md,
        marginRight: -theme.spacing.md,
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        maxWidth: 250,
        margin: 'auto',

        boxShadow: '0 3px 6px #00000029',
        'img': {
            width: '100%'
        }
    },

    links: {
        marginLeft: -theme.spacing.md,
        marginRight: -theme.spacing.md,
    },

    linksInner: {
        paddingTop: theme.spacing.xl,
        paddingBottom: theme.spacing.xl,
    },

    footer: {
        marginLeft: -theme.spacing.md,
        marginRight: -theme.spacing.md,
        borderTop: `1px solid #41284E`,
    },
}));

export function NavbarNested({opened, setOpened, setColorScheme, links}: any) {
    const {classes} = useStyles();
    const navigate = useNavigate()
    const theme = useMantineTheme()
    const {authenticated, user} = useSanctum()

    const createLinks = () => {
        if (links) {
            return links
        } else return baseLinks
    }

    if (!authenticated) return null;

    return (
        <Navbar p="md" pb='5px' hiddenBreakpoint="sm" hidden={!opened} width={{sm: 200, lg: 260}}
                className={classes.navbar}>

            <Navbar.Section className={classes.header}>
                <Link to={'/dashboard'}>
                    <img src={avaroLogo} alt={'missing content'}/>
                </Link>
            </Navbar.Section>

            <Group position={'right'}>

                <MediaQuery largerThan="sm" styles={{display: 'none'}}>
                    <Burger
                        opened={opened}
                        onClick={() => setOpened((o) => !o)}
                        size="sm"
                        color={theme.colors.gray[2]}/>
                </MediaQuery>
            </Group>

            <Navbar.Section grow className={classes.links} component={ScrollArea}>
                <div className={classes.linksInner}>{createLinks().map((item, index) => <LinksGroup {...item}
                                                                                                    hasIcon={!!item.icon}
                                                                                                    closeMenu={() => setOpened(false)}
                                                                                                    key={`linksGroup-${index}`}/>)}</div>
            </Navbar.Section>

            <Navbar.Section className={classes.footer}>
                <UserButton
                    user={user}
                    onClick={() => {
                        navigate('/profile')
                        setOpened(false)
                    }}
                />
            </Navbar.Section>
        </Navbar>
    );
}
