import {cleanupAxiosData} from "../helpers/helpers";
import {API} from "../helpers/api";

export const getProfile = (id: string = undefined, getToken: any = undefined) => {
    return API.get(id ? `/profile/${id}` : `/profile`, {
        cancelToken: getToken
    })
}
export const updateProfile = (id: string = undefined, data, getToken: any = undefined) => {
    return API.put(id ? `/profile/${id}` : `/profile`,
        cleanupAxiosData(data),
        {cancelToken: getToken,}
    )
}

export const getOrganization = (id: string = undefined, getToken: any = undefined) => {
    return API.get(id ? `/organization/${id}` : `/organization`, {
        cancelToken: getToken
    })
}
export const updateOrganization = (id: string = undefined, data, getToken: any = undefined) => {
    return API.put(id ? `/organization/${id}` : `/organization`, cleanupAxiosData(data),{
        cancelToken: getToken
    })
}
