import {useBaseObjectForm} from "../../hooks/hooks";
import {Button, Divider, PasswordInput} from "@mantine/core";
import {closeModal} from "@mantine/modals";
import {getErrorMessage} from "../../helpers/helpers";

const ChangePassword = () => {
    const {data, onDataChange, errors}: any = useBaseObjectForm({
        password: '',
        new_password: '',
        password_confirmation: ''
    })

    return <>
        <PasswordInput label="Old password"
                       data-autofocus
                       value={data.password}
                       error={getErrorMessage(errors, 'password')}
                       onChange={(e) => onDataChange({password: e.target.value})}
        />
        <Divider mb={10} mt={25}/>
        <PasswordInput label="New password"/>
        <PasswordInput label="Repeat new password"/>
        <Button fullWidth onClick={() => closeModal('password-change')} mt="md">
            Change
        </Button>
    </>
}
export default ChangePassword
