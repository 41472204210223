import {pageBaseStyles} from "../../styles";
import {Button, Checkbox, Grid, Loader, LoadingOverlay, Table, Title} from "@mantine/core";
import {useEffect, useState} from "react";
import {Refresh, Trash, X} from "tabler-icons-react";
import TableLoadingSkeleton from "../../components/TableLoadingSkeleton";
import DomainStatusBox from "../../components/DomainStatusBox";
import {useParams} from "react-router-dom";
import {API} from "../../helpers/api";

const DomainShow = () => {
    const {id} = useParams()
    const [loading, setLoading] = useState(false)
    const baseClasses = pageBaseStyles().classes
    const [data, setData]: any = useState({})
    const microsoftMock = [
        {name: 'MX'},
        {name: 'TXT'},
        {name: 'CNAME'}
    ]

    const getData = () => {
        setLoading(true)
        API.get(`/domains/${id}`)
            .then(r => {
                setData(r.data)
                setLoading(false)
            })
            .catch(e => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getData()
    }, [id]);


    return <>
        <div style={{display: 'flex', gap: 5, alignItems: 'center', marginBottom: 20}}>
            <div style={{fontSize: 20}}>
                <span style={{fontWeight: 400}}>Domain - </span>
                <span style={{fontWeight: 600}}>{`${data && data.name ? data.name : ''}`}</span>
            </div>
            <Button compact size={'xs'} variant={'subtle'} onClick={() => getData()} disabled={loading}
                    leftIcon={<Refresh size={18}/>}>Refresh</Button>
            <Button compact size={'xs'} variant={'subtle'} leftIcon={<Trash size={18}/>} disabled={loading}>
                Delete domain
            </Button>
        </div>


        <Grid>
            <Grid.Col xl={6} lg={12}>
                <div className={baseClasses.card} style={{height: "100%"}}>
                    <Title mb={20} order={5}>Domain status</Title>

                    {
                        loading
                            ? <Loader size={'sm'}/>
                            : <DomainStatusBox item={data}/>
                    }

                    {
                        data.status && data.status_details && <div style={{marginTop: 15}}>
                            {data.status_details}
                        </div>
                    }

                </div>
            </Grid.Col>

            <Grid.Col xl={6} lg={12}>
                <div className={baseClasses.card} style={{height: "100%", position: 'relative'}}>
                    <LoadingOverlay visible={loading}/>
                    <Title mb={20} order={5}>
                        DNS records
                    </Title>


                    <Title order={6} mb={10} mt={10}>Microsoft exchange</Title>
                    <Table highlightOnHover style={{tableLayout: 'fixed'}} fontSize={12}
                           className={'table-avr ' + baseClasses.table}>
                        <thead>
                        <tr>
                            <th style={{width: 40}}/>
                            <th>Type</th>
                            <th style={{width: 120}}>Status</th>
                            <th>Name</th>
                            <th>Value</th>
                            <th>TTL</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            loading
                                ? <TableLoadingSkeleton columnCount={5}/>
                                : <>
                                    {
                                        microsoftMock && microsoftMock.length > 0 && microsoftMock.map((item, index) =>
                                            <tr key={`ii-${index}`}>
                                                <td>
                                                    <Checkbox size={"xs"}/>
                                                </td>
                                                <td>
                                                    <a className={baseClasses.link}>{item.name}</a>
                                                </td>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                            </tr>
                                        )
                                    }
                                </>
                        }
                        </tbody>
                    </Table>

                </div>
            </Grid.Col>


            <Grid.Col xl={6} lg={12}>
                <div className={baseClasses.card} style={{height: "100%"}}>
                    <Title order={6} mb={10} mt={10}>Other</Title>

                </div>
            </Grid.Col>
        </Grid>
    </>
}

export default DomainShow
