import {createStyles, Group, Burger, MediaQuery, useMantineTheme, TextInput} from '@mantine/core';
import {Search} from "tabler-icons-react";
import {useSanctum} from "react-sanctum";

const useStyles = createStyles((theme) => ({
    header: {
        marginTop: -16,
        marginRight: -16,
        marginLeft: -16,
        marginBottom: 16,
        paddingRight: theme.spacing.md,
        height: 74,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        boxShadow: theme.colorScheme === 'dark' ? '0 3px 6px #94949c00' : '0 3px 6px #94949C4D',
    },

    inner: {
        height: 62,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    links: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    search: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        padding: '3px 10px 3px 26px',
        height: '100%',
        color: theme.colors.gray[6],
        fontSize: '0.88rem',
        letterSpacing: 0.4,
        [theme.fn.smallerThan('xs')]: {
            display: 'none',
        },
    },

    link: {
        display: 'block',
        lineHeight: 1,
        padding: '8px 12px',
        borderRadius: theme.radius.sm,
        textDecoration: 'none',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
        fontSize: theme.fontSizes.sm,
        fontWeight: 500,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },
    },
}));

export function HeaderSearch({opened, setOpened}) {
    const {authenticated, user} = useSanctum()
    const theme = useMantineTheme()
    const {classes} = useStyles();

    const topLinks = [
        {
            link: "/about",
            label: "Features",
            show: true
        },
        {
            link: "/pricing",
            label: "Pricing",
            show: true
        },
        {
            link: "/learn",
            label: "Learn",
            show: true
        },
        {
            link: "/community",
            label: "Community",
            show: true

        },
        {
            link: "/admin",
            label: "Admin",
            show: authenticated && user && user.id
        }
    ]

    const items = topLinks.map((link) => (
        <a
            key={link.label}
            href={link.link}
            className={classes.link}
            onClick={(event) => event.preventDefault()}>
            {link.label}
        </a>
    ));

    return (
        <div className={classes.header}>
            {/*<UnstyledButton className={classes.search} onClick>*/}
            {/*    <Search size={18}/>*/}
            <TextInput placeholder={'Search for platform module...'}
                       variant={'unstyled'}
                       icon={<Search size={18}/>}
                       styles={{
                           root: {height: '100%', maxWidth: 600, width: '100%', paddingLeft: 15},
                           wrapper: {height: '100%'},
                           input: {height: '100%'}
                       }}/>
            {/*<div>Search for platform module...</div>*/}
            {/*</UnstyledButton>*/}
            <div className={classes.inner}>
                <Group>
                    <MediaQuery largerThan="sm" styles={{display: 'none'}}>
                        <Burger
                            opened={opened}
                            onClick={() => setOpened((o) => !o)}
                            size="sm"
                            color={theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[7]}
                        />
                    </MediaQuery>

                </Group>

                <Group>
                    <Group ml={50} spacing={5} className={classes.links}>
                        {items}
                        {/*<ThemeToggle setColorScheme={setColorScheme}/>*/}
                    </Group>
                    {/*<Autocomplete*/}
                    {/*    className={classes.search}*/}
                    {/*    placeholder="Search"*/}
                    {/*    icon={<IconSearch size={16} stroke={1.5}/>}*/}
                    {/*    data={['React', 'Angular', 'Vue', 'Next.js', 'Riot.js', 'Svelte', 'Blitz.js']}*/}
                    {/*/>*/}
                </Group>
            </div>
        </div>
    );
}
