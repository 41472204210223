import {Button, Grid, Table, TextInput, Title, Badge} from "@mantine/core";
import {Link} from "react-router-dom";
import {getErrorMessage} from "../../../helpers/helpers";
import {updateOrganization} from "../../../backend/userAxios";
import {showDefaultNotification} from "../../../backend/defaultNotifications";
import {useSanctum} from "react-sanctum";

const OrgOverview = ({classes, data}) => {
    return <Grid>
        <Grid.Col sm={6} xs={12}>
            <div className={classes.heading}>Latest Invoice</div>

            <div className={classes.content}>
                {
                    data && data.latest_invoice && data.latest_invoice &&
                    <div style={{display: 'flex', gap: 20, alignItems: 'center'}}>
                        <div>{data.latest_invoice.date}</div>
                        <div><Badge color={'red'} size={'xs'}>unpaid</Badge></div>
                    </div>
                }
                <Link className={classes.link_button} to={'/organization/invoices'}>View all</Link>
            </div>
        </Grid.Col>

        <Grid.Col sm={6} xs={12}>
            <div className={classes.heading}>Admins</div>

            <div className={classes.content}>
                {
                    data && data.admins && data.admins.map(item => <div
                        key={item.id}>{item.display_name}</div>)
                }
                <a className={classes.link_button}>Manage</a>
            </div>
        </Grid.Col>
    </Grid>
}

const OrgSettings = ({data, onDataChange, errors, setErrors}) => {
    const {user} = useSanctum()
    const onSubmit = () => {
        updateOrganization(user.organization_id, data)
            .then(() => {
                showDefaultNotification({title: 'Data updated'})
            })
            .catch(e => {
                if (e.response.status === 422) {
                    setErrors(e.response.data.errors)
                }
            })
    }

    return <>
        <Grid gutter={'xl'}>
            <Grid.Col xs={12} sm={6} md={6}>
                <Title order={4} mb={10} mt={0}>Organization information</Title>
                <Grid>
                    <Grid.Col span={12}>
                        <TextInput label={'Name'}
                                   error={getErrorMessage(errors, 'name')}
                                   required
                                   value={data.name}
                                   onChange={(e) => onDataChange({name: e.target.value})}/>
                    </Grid.Col>

                    <Grid.Col>
                        <Grid>
                            <Grid.Col xs={12} md={6}>
                                <TextInput label={'Country'}
                                           error={getErrorMessage(errors, 'country')}
                                           required
                                           value={data.country}
                                           onChange={(e) => onDataChange({country: e.target.value})}/>
                            </Grid.Col>
                            <Grid.Col xs={12} md={6}>
                                <TextInput label={'State'}
                                           error={getErrorMessage(errors, 'state')}
                                           required
                                           value={data.state}
                                           onChange={(e) => onDataChange({state: e.target.value})}/>
                            </Grid.Col>
                            <Grid.Col xs={12} md={6}>
                                <TextInput label={'City'}
                                           error={getErrorMessage(errors, 'city')}
                                           required
                                           value={data.city}
                                           onChange={(e) => onDataChange({city: e.target.value})}/>
                            </Grid.Col>
                            <Grid.Col xs={12} md={6}>
                                <TextInput label={'Street address'}
                                           error={getErrorMessage(errors, 'street')}
                                           required
                                           value={data.street}
                                           onChange={(e) => onDataChange({street: e.target.value})}/>
                            </Grid.Col>
                            <Grid.Col xs={12} md={6}>
                                <TextInput label={'Apartment or suite'}
                                           error={getErrorMessage(errors, 'apartment')}
                                           required
                                           value={data.apartment}
                                           onChange={(e) => onDataChange({apartment: e.target.value})}/>
                            </Grid.Col>
                            <Grid.Col xs={12} md={6}>
                                <TextInput label={'ZIP or postal code'}
                                           error={getErrorMessage(errors, 'post_code')}
                                           required
                                           value={data.post_code}
                                           onChange={(e) => onDataChange({post_code: e.target.value})}/>
                            </Grid.Col>
                        </Grid>
                    </Grid.Col>

                    <Grid.Col xs={12} md={6}>
                        <TextInput label={'Email'}
                                   error={getErrorMessage(errors, 'email')}
                                   required
                                   value={data.email}
                                   onChange={(e) => onDataChange({email: e.target.value})}/>
                    </Grid.Col>
                    <Grid.Col xs={12} md={6}>
                        <TextInput label={'Phone'}
                                   error={getErrorMessage(errors, 'phone')}
                                   required
                                   value={data.phone}
                                   onChange={(e) => onDataChange({phone: e.target.value})}/>
                    </Grid.Col>
                </Grid>
            </Grid.Col>

            <Grid.Col xs={12} sm={6} md={6}>
                <Title order={4} mb={10} mt={10}>Billing information</Title>
                <Table className={'table-avr'} fontSize={'xs'}>
                    <thead>
                    <tr>
                        <th>Billing address</th>
                        {/*<th>Document language</th>*/}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{padding: 0}}>
                            <TextInput variant={'filled'}
                                       radius={0}
                                       value={data.billing_email}
                                       error={getErrorMessage(errors, 'billing_email')}
                                       onChange={(e) => onDataChange({billing_email: e.target.value})}/>
                        </td>
                    </tr>
                    </tbody>
                </Table>

            </Grid.Col>


        </Grid>
        <div style={{display: 'flex', justifyContent: "flex-end", marginTop: 10}}>
            <Button compact mt={10} p={'1px 20px'} onClick={() => onSubmit()}> Save</Button>
        </div>
    </>
}


export {OrgOverview, OrgSettings};
