import {createStyles, Grid, TextInput, Title, Button, Divider, useMantineTheme, Anchor} from "@mantine/core";
import {Key, Lock} from "tabler-icons-react";
import {useNavigate} from "react-router-dom";
import {callPasswordChangeModal} from "../../hooks/hooks";
import {useSanctum} from "react-sanctum";
import {useBaseObjectForm} from "../../helpers/helpers";
import {API} from "../../helpers/api";
import {showDefaultNotification} from "../../backend/defaultNotifications";

const UserProfile = () => {
    const navigate = useNavigate()
    const theme = useMantineTheme()
    const {classes} = useStyles()
    const {signOut, user} = useSanctum()

    const {data, errors, setErrors, onDataChange}: any = useBaseObjectForm({
        ...user
    })

    const performLogout = () => {
        signOut().then(() => navigate('/login'))
    }

    const updateProfile = () => {
        API.put('/profile', {
            ...data
        }).then(r=>{
            showDefaultNotification({title: 'Profile updated'})
        }).catch(e=>{
            setErrors(e.response.data.errors)
        })
    }

    return <>
        <div className={classes.card}>
            <div className={classes.header}>
                <div className={classes.header_user}>
                    <Title order={3} weight={500}
                           style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                        {user.display_name}
                    </Title>
                    <div style={{display: 'flex', rowGap: 4, columnGap: 15, marginTop: 5, flexWrap: 'wrap'}}>
                        <a className={classes.link_button_wi} onClick={() => callPasswordChangeModal()}>
                            <Key strokeWidth={1.6} size={16}/>
                            Change password
                        </a>
                        <div onClick={() => performLogout()} className={classes.link_button_wi} style={{color: theme.colors.red[8]}}>
                            <Lock strokeWidth={1.6} size={16}/>
                            Logout
                        </div>
                    </div>
                </div>
            </div>

            <Divider mt={15} mb={10}/>

            <Grid style={{maxWidth:1140}}>
                <Grid.Col sm={6} xs={12}>
                    <div className={classes.heading}>Username</div>

                    <div className={classes.content}>
                        <div>{user.email}</div>
                        <a className={classes.link_button}>Manage</a>
                    </div>
                </Grid.Col>

                <Grid.Col sm={6} xs={12}>
                    <div className={classes.heading}>Groups</div>

                    <div className={classes.content_group}>
                        <div>Avaro Technologies</div>
                        <div>Admins</div>
                    </div>
                </Grid.Col>
            </Grid>

            <Divider mt={25} mb={25}/>

            <Grid style={{maxWidth:1140}}>
                <Grid.Col lg={8} md={12}>
                    <Grid>
                        <Grid.Col span={12}>
                            <TextInput value={data.first_name} label={'First name'} onChange={(e) => onDataChange({first_name: e.target.value})} required/>
                        </Grid.Col>

                        <Grid.Col span={12}>
                            <TextInput value={data.last_name} label={'Last name'} onChange={(e) => onDataChange({last_name: e.target.value})} required/>
                        </Grid.Col>

                        <Grid.Col sm={6} xs={12}>
                            <TextInput value={data.display_name} readOnly label={'Display name'} />
                        </Grid.Col>

                        <Grid.Col sm={6} xs={12}>
                            <TextInput value={data.job_title} label={'Job title'} onChange={(e) => onDataChange({job_title: e.target.value})} />
                        </Grid.Col>

                        <Grid.Col sm={6} xs={12}>
                            <TextInput value={data.email} label={'Email'} onChange={(e) => onDataChange({email: e.target.value})} required/>
                        </Grid.Col>

                        <Grid.Col sm={6} xs={12}>
                            <TextInput value={data.phone} label={'Phone'} onChange={(e) => onDataChange({phone: e.target.value})} />
                        </Grid.Col>
                    </Grid>
                </Grid.Col>
            </Grid>

            <div style={{display: 'flex', justifyContent: "flex-end", marginTop: 15}}>
                <Button p={'5px 20px'} compact onClick={updateProfile}>Save</Button>
            </div>
        </div>
    </>
}
export default UserProfile

const useStyles = createStyles((theme) => ({
    card: {
        padding: 20,
        background: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        color: theme.colorScheme === 'dark' ? theme.colors.gray[4] : theme.colors.dark[7]
    },
    tabs: {
        marginTop: 25
    },
    heading: {
        fontSize: '1rem',
        fontWeight: 600,
        marginBottom: 5
    },
    content: {
        fontSize: '0.9rem',
        fontWeight: 400,
        marginBottom: 5
    },
    content_group: {
        fontSize: '0.9rem',
        fontWeight: 400,
        display: 'flex',
        flexDirection: 'column',
    },
    link_button: {
        fontSize: 12,
        color: '#7248e6',
        fontWeight: 500,
        cursor: 'pointer'
    },
    link_button_wi: {
        fontSize: 12,
        color: '#7248e6',
        fontWeight: 500,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: 2
    },
    header: {
        display: 'flex',
        gap: 20,
        alignItems: 'center',
        overflow: "hidden",
        textOverflow: 'ellipsis',
    },
    header_user: {
        overflow: "hidden",
        textOverflow: 'ellipsis',
    }
}));
