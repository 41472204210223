import {AppShell, useMantineTheme, ColorSchemeProvider, MantineProvider} from "@mantine/core";
import {NotificationsProvider} from "@mantine/notifications";
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {ModalsProvider} from "@mantine/modals";

import {MantineThemeObject} from "../theme/themeObject";
import {useEffect, useState} from "react";
import {NavbarNested} from "./layout/Navbar";
import {HeaderSearch} from "./layout/Header";
import Login from "./pages/auth/Login";
import UserProfile from "./pages/user/UserProfile";
import OrganizationOverview from "./pages/organization/OrganizationOverview";
import BillingList from "./pages/organization/BillingList";

import Dashboard from "./pages/dashboard/Dashboard";
import ApiKeyList from "./pages/api/ApiKeyList";
import OrganizationDomainList from "./pages/organization/OrganizationDomainList";
import DomainShow from "./pages/organization/DomainShow";
import IncidentList from "./pages/tickets/IncidentList";
import Tickets from "./pages/tickets/Tickets";
import ChangesList from "./pages/tickets/ChangesList";
import RequestsList from "./pages/tickets/RequestsList";
import OrganizationPeople from "./pages/organization/OrganizationPeople";
import NavbarLinkWrapper from "./hooks/NavbarLinkWrapper";
import {Authenticated} from "./providers/AuthProvider";
import {useSanctum} from "react-sanctum";


const AppRouter = () => {
    const {authenticated} = useSanctum()
    const theme = useMantineTheme()
    const location = useLocation()
    const [opened, setOpened] = useState(false)

    const [colorScheme, setScheme]: any = useState("light");

    useEffect(() => {
        if (localStorage.getItem('theme')) setScheme(localStorage.getItem('theme'))
        else setColorScheme('light')
    }, [])

    useEffect(() => {
    }, [location.pathname])

    const setColorScheme = (colorT) => {
        setScheme(colorT)
        localStorage.setItem('theme', colorT)
    }


    return <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={setColorScheme}>
        <MantineProvider withGlobalStyles
                         withNormalizeCSS
                         theme={{...MantineThemeObject, colorScheme: colorScheme}}>
            <NotificationsProvider position={'top-right'} autoClose={5000}>
                <ModalsProvider>
                    <NavbarLinkWrapper>
                        {(getNavLinks) => (
                            <>
                                <AppShell
                                    styles={{
                                        main: {
                                            background: colorScheme === 'dark' ? theme.colors.dark[8] : '#e8e9ec',
                                            transition: '0.2s',
                                        },
                                    }}
                                    navbarOffsetBreakpoint="sm"
                                    navbar={
                                        <NavbarNested opened={opened}
                                                      setOpened={setOpened}
                                                      links={getNavLinks}
                                                      setColorScheme={setColorScheme}/>
                                    }
                                    hidden={!authenticated}>

                                    {
                                        authenticated &&
                                        <HeaderSearch opened={opened} setOpened={setOpened}/>
                                    }
                                    <Routes>
                                        <Route element={<Authenticated/>}>
                                            <Route path='login' element={<Login page={'login'}/>}/>
                                            <Route path='forgot' element={<Login page={'forgot'}/>}/>

                                            <Route path='dashboard' element={<Dashboard/>}/>
                                            <Route path='profile' element={<UserProfile/>}/>

                                            <Route path={'sms_sending'}>
                                                <Route path='' element={<div>Sms sending dashboard</div>}/>
                                                <Route path=':id' element={<Dashboard/>}/>
                                            </Route>

                                            <Route path={'email_sending'}>
                                                <Route path='' element={<div>email sending dashboard</div>}/>
                                                <Route path=':id' element={<Dashboard/>}/>
                                            </Route>

                                            <Route path={'organization'}>
                                                <Route path='' element={<OrganizationOverview/>}/>
                                                <Route path='invoices' element={<BillingList/>}/>
                                                <Route path='people' element={<OrganizationPeople/>}/>

                                                <Route path={'domains'}>
                                                    <Route path='' element={<OrganizationDomainList/>}/>
                                                    <Route path=':id' element={<DomainShow/>}/>
                                                </Route>
                                            </Route>

                                            <Route path={'tickets'}>
                                                <Route path='' element={<Tickets/>}/>
                                                <Route path='incidents' element={<IncidentList/>}/>
                                                <Route path='changes' element={<ChangesList/>}/>
                                                <Route path='requests' element={<RequestsList/>}/>

                                                <Route path='incidents/:id' element={<OrganizationOverview/>}/>
                                            </Route>

                                            <Route path={'api_keys'}>
                                                <Route path='' element={<ApiKeyList/>}/>
                                            </Route>

                                        </Route>

                                        <Route path='*' element={<Navigate to={'login'}/>}/>
                                    </Routes>
                                </AppShell>
                            </>
                        )}
                    </NavbarLinkWrapper>

                </ModalsProvider>
            </NotificationsProvider>
        </MantineProvider>
    </ColorSchemeProvider>
}

export default AppRouter


